.MuiDateCalendar-root {
    width: 250px !important;

    .MuiPickersCalendarHeader-labelContainer {
        font-size: 0.8rem;
        font-family: 'Public Sans';
    }

    .MuiPickersArrowSwitcher-button {
        font-size: 1.1rem;
    }

    .MuiDayCalendar-weekDayLabel,
    .MuiPickersDay-root {
        width: 32px;
        height: 32px;
        margin: 0;
        font-size: 0.6rem;
        font-family: 'Public Sans';
    }

    .MuiDayCalendar-slideTransition {
        min-height: 210px;
    }
}
