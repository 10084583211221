@import "public/assets/fonts/Stolzl/stylesheet.css";

// Montserrat

@font-face {
  font-family: Montserrat;
  font-weight: 100;
  src: url("../public/assets/fonts/montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  src: url("../public/assets/fonts/montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url("../public/assets/fonts/montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url("../public/assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("../public/assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url("../public/assets/fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("../public/assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 800;
  src: url("../public/assets/fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url("../public/assets/fonts/montserrat/Montserrat-Black.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 100;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 800;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  font-style: italic;
  src: url("../public/assets/fonts/montserrat/Montserrat-BlackItalic.ttf") format("truetype");
}

// Public Sans

@font-face {
  font-family: 'Public Sans';
  font-weight: 100;
  src: url("../public/assets/fonts/publicSans/PublicSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 200;
  src: url("../public/assets/fonts/publicSans/PublicSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 300;
  src: url("../public/assets/fonts/publicSans/PublicSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 400;
  src: url("../public/assets/fonts/publicSans/PublicSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 500;
  src: url("../public/assets/fonts/publicSans/PublicSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 600;
  src: url("../public/assets/fonts/publicSans/PublicSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 700;
  src: url("../public/assets/fonts/publicSans/PublicSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 800;
  src: url("../public/assets/fonts/publicSans/PublicSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 900;
  src: url("../public/assets/fonts/publicSans/PublicSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 100;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 200;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 300;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 400;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 500;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 600;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 700;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 800;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Public Sans';
  font-weight: 900;
  font-style: italic;
  src: url("../public/assets/fonts/publicSans/PublicSans-BlackItalic.ttf") format("truetype");
}



